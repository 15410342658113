import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import ProductCardImage from './ProductCardImage';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import { ImageScalingTypes } from 'Shared/Common/ResizeImageEgmontCDN';
import { FocusVisibleStyle } from 'DesignSystem/Accessibility/Utils';
import PrepaidSubscriptionOfferCardModel from 'Models/SubscriptionOffer/PrepaidSubscriptionOfferCardModel.interface';
import {
  AddPrepaidSubscriotionOffer,
  FetchCartAndNotifyAll,
} from 'Commerce/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { useState } from 'react';

type ProductCardType = {
  item: PrepaidSubscriptionOfferCardModel;
  selectedModel?: string;
  inEditMode?: boolean;
  css?: CSS;
  width?: number; //Will be 1/4 of the content area width if renderd in a Product card list
};

function PrepaidSubscriptionOfferCard({ item, width, css }: ProductCardType) {
  const { name, code, giftCard } = item;
  const {
    languageRoute,
    staticPages: { checkoutPage },
  } = useAppSettingsData();
  const kexNavigate = useKexNavigate();
  const [addToCartInProgress, setAddToCartInProgress] = useState(false);

  const handleCardClick = async (event: React.MouseEvent<HTMLElement>) => {
    if (code && giftCard && !addToCartInProgress) {
      setAddToCartInProgress(true);
      event.preventDefault();
      const response = await AddPrepaidSubscriotionOffer(
        code,
        giftCard,
        languageRoute
      );

      if (response.ok) {
        await FetchCartAndNotifyAll(languageRoute);
        kexNavigate(checkoutPage);
      }

      setAddToCartInProgress(false);
    }
  };

  const secondaryImage =
    'egmontPremiumMainImage' in item ? item.egmontPremiumMainImage : undefined;

  return (
    <Card css={width ? { ...css, minW: width } : css} onClick={handleCardClick}>
      <div>
        <ProductCardImage
          image={item.image}
          premiumImage={secondaryImage}
          imageScalingType={ImageScalingTypes.PRODUCT_CARD}
        />
      </div>
      <InfoWrapper>
        <TitleAndSubTitle>
          <CardLink href="#">{name}</CardLink>
          {item.egmontNameOfAdditionalItems && (
            <SubTitleText>
              {'+ ' + item.egmontNameOfAdditionalItems}
            </SubTitleText>
          )}
        </TitleAndSubTitle>
      </InfoWrapper>
    </Card>
  );
}
const CardLink = styled('a', {
  fontSize: '$fontSize200',
  fontFamily: 'fontSemiBold',
  lineHeight: '$lh24',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  '@bpMax480': {
    fontSize: '$fontSize75',
  },
  '&:focus-visible': {
    outline: 'none !Important',
  },
});

const InfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 2,
  height: '100%',
});

const TitleAndSubTitle = styled('div', {
  mb: 2,
});
const SubTitleText = styled('div', {
  fontSize: '$fontSize75',
  lineHeight: '$lh20',
  fontFamily: 'fontMedium',
  pt: 1,
});
const Card = styled('article', {
  color: '$onSurface',
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  w: 'auto',
  minW: 100,
  height: '100%',
  '&:hover': {
    [`& ${CardLink}`]: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      textDecorationThickness: ' 2px',
    },
  },
  '&:has(a:focus-visible)': {
    ...FocusVisibleStyle,
  },
  '@bpMax720': {
    pb: 5,
  },
});
export default PrepaidSubscriptionOfferCard;
