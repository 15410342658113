import { CSS } from '@stitches/react';
import React from 'react';
import { styled } from 'Theme/stitches.config';

type PropTypes = {
  children: React.ReactNode;
  noGutter?: boolean;
  noGap?: boolean;
  noPadding?: boolean;
  showScrollbar?: boolean;
  css?: CSS;
};

function Grid({
  children,
  noGutter,
  noGap,
  noPadding,
  css,
  showScrollbar = false,
  ...rest
}: PropTypes) {
  return (
    <GridContainer
      css={css}
      showScrollbar={showScrollbar}
      {...rest}
      {...(noGutter && { noGutter })}
      {...(noGap && { noGap })}
      {...(noPadding && { noPadding })}
    >
      {children}
    </GridContainer>
  );
}

const GridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
  gridGap: '$s100',
  px: '$s100',
  py: 1,
  '@bpMin601': {
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
  },
  '@bpMin721': {
    px: '$s300',
    gridTemplateColumns: 'repeat(8, minmax(0, 1fr))',
    gridGap: '$s150',
  },
  '@bpMin1025': {
    px: '$s400',
    gridGap: '$s200',
  },
  '@bpMin1281': {
    gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  },
  '@bpMin1441': {
    mx: 'auto',
  },
  variants: {
    noGutter: {
      true: {
        px: 'unset',
      },
    },
    noGap: {
      true: {
        g: 'unset',
      },
    },
    noPadding: {
      true: {
        px: 0,
      },
    },
    showScrollbar: {
      true: {
        overflowX: 'auto',
        overflowY: 'hidden',
        py: 3,
        '&::-webkit-scrollbar-track': {
          backgroundColor: '$interactiveSubtleBackgroundDefault',
        },
        '&::-webkit-scrollbar': {
          h: 4,
          backgroundColor: '$interactiveSubtleBackgroundDefault',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '$onSurface',
        },
      },
    },
  },
});

export default Grid;
